// Tab Llamadas Expedientes // cabecera exped 50px mas contenedor // tabs 30px
//anchura // 1600px de anchura total // container (padding 12+12=24) 1576px //
principal-row (padding-right 8) 1568px // a cada marco sumar (margin-rigth 8px)
// marcos 2x776px=1552px (mas 16px de margin=1568px ) // marcos 3x513,3px (mas
24px de margin=1568px) // marcos 4x384px=1536px (mas 32px de margin=1568px) //
altura 679px //principal-row 691px (padding-top 12px) 679px //a cada marco sumar
(margin-bottom 8px) // a cada cabecera sumar 15px (23px cabecera mas marco) //
marcos 2x316,5px (mas 46px de cabecera y margen marco ) // marcos 3x203,3px (mas
69px de margin=1568px) // marcos 4x146,75px (mas 92px de margin=1568px)

<template>
<!-- style="width:1600px;background-color: #eee;" -->
  <div
    class=" expMLlamada conflex pt-3 "
    style="justify-content:center">    
  
    <div class="columna">      
      <!-- solicitante --------------------------------------------------------------->
      <div class="cab">SOLICITANTE</div>
      <v-sheet width="776px" v-bind="$cfg.styles.marco">      
        <div style="display:flex;align-items:center">        
          <uiMultiple
            :style="noEdit || APP!=1? 'width:100%' : 'width:95%'"
            :ctrl="masterCt.callemp"
            :ct="masterCt"                  
            :disabled="noEdit || APP!= 1">            
          </uiMultiple>
      
          <v-btn 
            v-show="(estado=='editar' || estado=='nuevo') && APP== 1" 
            v-bind="$cfg.btn.busca"
            style="margin-left:5px"
            @click="getPoliza()">
              <v-icon large color="green darken-2">{{ 'mdi-alpha-p' }}</v-icon>
          </v-btn>
        </div>

        <div style="display:flex">
          <uiTf
            style="width:75%"
            :ct="masterCt.callname"
            :disabled="noEdit || APP!= 1">                      
          </uiTf>

          <uiTf
            style="width:25%"
            :ct="masterCt.calltlf"
            :disabled="noEdit || APP!= 1">                      
          </uiTf>
        </div>

        <div style="display:flex">
          <uiTf            
            style="width:65%"
            :ct="masterCt.callemail"
            :disabled="noEdit || APP!= 1">                      
          </uiTf>

          <uiTf
            style="width:35%"
            :ct="masterCt.callref1"
            :disabled="noEdit || APP!= 1">                      
          </uiTf>
        </div>

        <!-- </div> -->
      </v-sheet>

      <!-- Poliza --------------------------------------------------------------->
      <div class="cab">POLIZA</div>
      <v-sheet width="776px" height="490px" v-bind="$cfg.styles.marco">        
        <div style="display:flex">
          <uiTf
            style="width:30%"
            :ct="masterCt.poliza"
            :disabled="noEdit || APP!= 1">                      
          </uiTf>

          <uiTf
            style="width:70%"
            :ct="masterCt.fallename"
            :disabled="noEdit || APP!= 1">                      
          </uiTf>       
        </div>

        <div style="display:flex">
          <div style="width:30%"></div>
          <uiTf
            style="width:20%"
            :ct="masterCt.falledni"
            :disabled="noEdit"
            format="cif"
            @input="masterCt.falledni[2]= $event">
          </uiTf>

          <uiTf
            style="width:10%"
            :ct="masterCt.falleedad"
            :disabled="noEdit">                      
          </uiTf>
        
          <v-select
            style="width:15%"
            v-bind="$select"
            v-model="masterCt.fallesexo[2]"
            :label="masterCt.fallesexo[1]"
            :items="itemsSexo"
            :disabled="noEdit"
            item-value="name"
            item-text="name">
          </v-select>

          <v-select
            style="width:25%"
            v-bind="$select"
            v-model="masterCt.falleestadoc[2]"
            :label="masterCt.falleestadoc[1]"
            :items="estadoCivil"
            :disabled="noEdit"
            item-value="id"
            item-text="name">
          </v-select>
        </div>

        <div v-if="APP== 1 || (APP!= 1 && masterCt.callemp[2]=='83')">
          <div style="display:flex">
            <uiTf             
              style="width:30%"
              :ct="masterCt.clavep"
              :disabled="noEdit || APP!= 1"
              @change="changeClaveProduccion">                      
            </uiTf>      

            <ctrlF
              style="width:65%"
              :ct="masterCt.agencia"
              :label="masterCt.agencia[1]"
              :tipoView="tipoViewAgencia"
              mView="ciacM"
              :ctView="{
                afecta_name:['afecta_name', 'Agencia', ''],
                name:['name', 'Cuenta', ''],
                name_afecta_f:['name_afecta_f', 'Funeraria Asignada', ''],
                dire:['dire', 'Dirección', ''],
                name_loc:['name_loc', 'Origen', ''],
                name:['name', 'Cuenta', ''],
                cp:['cp', 'Código Postal', ''],
                cif:['cif', 'Cif', ''],
                tlf1:['tlf1', 'Teléfono', ''],
                fax1:['fax1', 'Fax', ''],
                email1:['email1', 'Email', ''],
                admonemail:['admonemail', 'Email Admon', ''],
                cta:['cta', 'Cuenta Bancaria'],
                obs:['obs', 'Observaciones', '', [], 
                {
                  compVirtual: {
                    type:'textarea'
                  }
                }]
              }"
              :edicion="!noEdit || APP!= 1">
            </ctrlF>
          </div>

          <div style="display:flex">         
            <uiDate
              style="width:25%" 
              v-model="masterCt.fhadhesion[2]"
              :label="masterCt.fhadhesion[1]"           
              :disabled="noEdit || APP!= 1">                      
            </uiDate>

            <v-checkbox
              style="flex: 1 0 10%"
              v-bind="$checkbox"
              v-model="masterCt.encargo[2]"
              :label="masterCt.encargo[1]"
              :disabled="noEdit || APP!= 1">
            </v-checkbox>
            
            <v-checkbox
              style="flex: 1 0 10%"
              v-bind="$checkbox"
              v-model="masterCt.poliza_pu[2]"
              :label="masterCt.poliza_pu[1]"
              :disabled="noEdit || APP!= 1">
            </v-checkbox>

            <v-checkbox
              style="flex: 1 0 10%"
              v-bind="$checkbox"
              v-model="masterCt.rat[2]"
              :label="masterCt.rat[1]"
              :disabled="noEdit || APP!= 1">
            </v-checkbox>

            <v-checkbox
              style="flex: 1 0 10%"
              v-bind="$checkbox"
              v-model="masterCt.ctepago[2]"
              :label="masterCt.ctepago[1]"
              :disabled="noEdit || APP!= 1">
            </v-checkbox>
            
            <uiTf
              style="width:20%"
              :ct="masterCt.me"
              :disabled="noEdit || APP!= 1">                      
            </uiTf>
          </div>

          <v-select
            style="width:50%"
            v-bind="$select"
            v-model="masterCt.ctramit[2]"
            :label="masterCt.ctramit[1]"
            :items="STCtramit"
            :disabled="noEdit || APP!= 1"
            item-value="id"
            item-text="label">
          </v-select>      
      
          <v-select
            style="width:50%"
            v-bind="$select"
            v-model="masterCt.modalidad[2]"
            :label="masterCt.modalidad[1]"
            :items="STModalidad"
            :disabled="noEdit || APP!= 1"
            item-value="id"
            item-text="name">
          </v-select>        

          <div style="display:flex">
            <v-select
              style="flex: 0 0 50%"
              v-bind="$select"
              v-model="masterCt.ramo[2]"
              :label="masterCt.ramo[1]"
              :items="STRamo"
              :disabled="noEdit || APP!= 1"
              item-value="id"
              item-text="name">
            </v-select>

            <div v-if ="masterCt.ramo[2]=='038' && APP== 1" style="padding-left:5px;">
              <span            
                style="color:red">
                  RAMO 038: Comunicados como negocio directo. Facturas a AFS.
                  Rellenar complemento en servicio.
              </span>
            </div>
          </div>
        
          <uiObs3
            v-if="APP== 1"
            :record="record"
            :schema="masterCt"
            :schemaComponente="masterCt.callobs"
            :edicion="!noEdit">                       
          </uiObs3>
        </div>

        <div v-if="APP!= 1 && masterCt.callemp[2]!='83'">
          <uiDate
            style="width:25%" 
            v-model="masterCt.fhadhesion[2]"
            :label="masterCt.fhadhesion[1]"           
            :disabled="noEdit || APP!= 1">                      
          </uiDate>

          <v-checkbox
            style="flex: 1 0 10%"
            v-bind="$checkbox"
            v-model="masterCt.encargo[2]"
            :label="masterCt.encargo[1]"
            :disabled="noEdit || APP!= 1">
          </v-checkbox>
          
          <v-checkbox
            style="flex: 1 0 10%"
            v-bind="$checkbox"
            v-model="masterCt.poliza_pu[2]"
            :label="masterCt.poliza_pu[1]"
            :disabled="noEdit || APP!= 1">
          </v-checkbox>
        </div>
      </v-sheet>
    </div>

    <div class="columna">
      <!-- solicitante -->

      <div class="cab">DATOS FALLECIMIENTO</div>
      <v-sheet width="776px" height="223px" v-bind="$cfg.styles.marco">
        <div style="display:flex">
          <uiDate
            style="flex: 0 0 35%"
            v-model="masterCt.fallefhhr[2]"
            :label="masterCt.fallefhhr[1]"          
            type="datetime-local"
            :disabled="noEdit">          
          </uiDate>
        </div>

        <div style="display:flex">
          <uiTf
            style="width:50%"
            :ct="masterCt.falleconsec"
            :disabled="noEdit">                      
          </uiTf>
      
          <v-checkbox
            v-bind="$checkbox"
            v-model="masterCt.fallejudicial[2]"
            :label="masterCt.fallejudicial[1]"
            :disabled="noEdit">
          </v-checkbox>

          <v-checkbox
            v-bind="$checkbox"
            v-model="masterCt.fallecmed[2]"
            :label="masterCt.fallecmed[1]"
            :disabled="noEdit">
          </v-checkbox>
        </div>

        <div style="display:flex">
          <uiTf
            :ct="masterCt.fallelugar"
            :disabled="noEdit">                      
          </uiTf>

          <ctrlF 
            style="width:50%"
            :ct="masterCt.fallelugarloc"
            :edicion="!noEdit && APP== 1">
          </ctrlF>       
        </div>

        <div style="display:flex">
          <uiTf
            :ct="masterCt.falleen"
            :disabled="noEdit">                      
          </uiTf>        

          <ctrlF 
            style="width:50%"
            :ct="masterCt.falleenloc"
            :edicion="!noEdit && APP== 1">
          </ctrlF>          
        </div>

        <div style="display:flex">
          <uiTf
            :ct="masterCt.falledest"
            :disabled="noEdit">                      
          </uiTf>

          <ctrlF 
            style="width:50%"
            :ct="masterCt.falledestloc"
            :edicion="!noEdit && APP== 1">
          </ctrlF>
        </div>
      </v-sheet>

      
      <div class="cab">CONTACTOS / FAMILIARES</div>
      <v-sheet width="776px" height="460px" v-bind="$cfg.styles.marco">
        <div style="display:flex">
          <uiTf         
            style="width:70%"
            :ct="masterCt.fallecont1"
            :disabled="noEdit">                      
          </uiTf>

          <uiTf
            style="width:30%"
            :ct="masterCt.fallecont1tlf1"
            :disabled="noEdit">                      
          </uiTf>
        </div>

        <div style="display:flex">
          <uiTf            
            style="width:70%"
            :ct="masterCt.fallecont2"
            :disabled="noEdit">                      
          </uiTf>

          <uiTf
            style="width:30%"
            :ct="masterCt.fallecont2tlf1"
            :disabled="noEdit">                      
          </uiTf>         
        </div>

        <div style="display:flex">
          <uiTf
            style="width:50%"
            :ct="masterCt.soliname"
            :disabled="noEdit">                      
          </uiTf>

          <uiTf
            :ct="masterCt.soliparen"
            :disabled="noEdit">                      
          </uiTf>

          <uiTf           
            :ct="masterCt.solidni"
            :disabled="noEdit"
            @input="masterCt.solidni[2]= $event">
          </uiTf>       
        </div>

        <div style="display:flex">
          <uiTf            
            :ct="masterCt.solitlf1"
            :disabled="noEdit">                      
          </uiTf>

          <uiTf
            :ct="masterCt.solitlf2"
            :disabled="noEdit">                      
          </uiTf>

          <uiTf
            style="width:50%"
            :ct="masterCt.soliemail"
            :disabled="noEdit">                      
          </uiTf>        
        </div>

        <div style="display:flex">
          <uiTf
            :ct="masterCt.solidir"
            :disabled="noEdit">                      
          </uiTf>

          <uiTf
            style="width:20%"
            :ct="masterCt.solicp"
            :disabled="noEdit">                      
          </uiTf>  
        </div>

        <div>
          <ctrlF
            :ct="masterCt.soliloc"
            :edicion="!noEdit">
          </ctrlF>          
        </div>

        <div>
          <uiObs3                       
            :record="record"
            :schema="masterCt"
            :schemaComponente="masterCt.soliobs"
            :edicion="!noEdit && APP== 1">
          </uiObs3>          
        </div>       
      </v-sheet>
    </div>
  </div>
</template>



<script>
  
  export default {
    props: {
      masterStore: { type: String, default: '' },
      masterCt: { type:Object, default:()=> {}}
    },

    data() {
      return {
        apiArgs: {},

        STCtramit: [],
        STModalidad: [],
        STRamo: [],
        estadoCivil: [],
        itemsSexo: [],            
        APP:this.$store.state.G.APP
      };
    },


    created() {
      this.iniDataParticular();
      this.iniDataApi();
    },
    

    methods: {
      iniDataParticular() {
        console.log("DEV ********************** (iniDataParticular)");
      
        // array de items
        this.STCtramit = this.$store.state.datos_iniciales.ct;
        this.STModalidad = this.$store.state.datos_iniciales.modalidades;
        this.STRamo = this.$store.state.datos_iniciales.ramos;
        this.estadoCivil = [
          { id: "0", name: "Seleccione Estado" },
          { id: "1", name: "Soltero/a" },
          { id: "2", name: "Casado/a" },
          { id: "3", name: "Divorciado/a" },
          { id: "4", name: "Viudo/a" }
        ];

        this.itemsSexo= [
          { id:'0', name:'H'},
          { id:'1', name:'M'}
        ];
      },


      // apiCall's particulares
      iniDataApi() { 
        this.apiArgs.servicio= ["", 'solicitud_servicio', { id:0 }, "expediente"];
        this.apiArgs.clave= ["", 'claveProdMF', { clave:'' }, "expediente"];
        this.apiArgs.agencia= ["", 'datos_cta', { tipo_cta:0, cta_id:0 }, "expediente"];        
      },



      // FUNCIONES COMUNES PARA GESTIÓN Y APDS
      ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////



      // FUNCIONES EXCLUSIVAS PARA EL MÓDULO DE GESTIÓN
      ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
      getPoliza() {
        console.log("DEV ********************* getPoliza", this.masterCt.poliza_MAPFRE, this.ct);

        if (this.masterCt.callemp[2]== '') return;
        if (this.masterCt.callemp[2]== 83) {
          
          // Póliza de MAPFRE
          this.$root.$commonF.openFinder({
            ct:this.masterCt.poliza_Finder,
            componenteName:'polizasMapfreF', 
            accion:'buscar',
            store:this.masterStore,
            fn: this.extraidPolizaMapfre
          });
          
          return;
        }
          
        // Póliza de Kutxabank   
        this.$root.$commonF.openFinder({
          ct:this.masterCt.poliza_Finder,
          componenteName:'polizasF', 
          accion:'',
          store:this.storeName,
          fn: this.extraidPoliza
        });
        
      },


      // entra al extraer una póliza de Kutxabank
      // actualiza controles del schema con los datos de la póliza extraida
      extraidPoliza(r) {        
        if (!r) return;
       
        if (r.aviso=='X') {
          this.$root.$alert.open('<u>ATENCIÓN</u><br><br>POLIZA MARCADA CON AVISO<br>*Compañia: <b>' + r.cia_name + "</b><br>", 'error');          
        }

        // Datos importados de la tabla de polizas poliza        
        this.$set(this.masterCt.callemp_tp, 2, "2");
        this.$set(this.masterCt.callemp, 2, r.cia_id); 
              
        this.$set(this.masterCt.poliza, 2, r.poliza);
        this.$set(this.masterCt.fallename, 2, r.name);
        this.$set(this.masterCt.falledni, 2, r.dni);
        this.$set(this.masterCt.fallesexo, 2, r.sex);
        this.$set(this.masterCt.falleedad, 2, r.edad);
        this.$set(this.masterCt.fhadhesion, 2, r.fh);        
        this.$set(this.masterCt.poliza_pu, 2, r.puni);
        this.$set(this.masterCt.inhumacion, 2, "1");
        this.$set(this.masterCt.incineracion, 2, "1");
        this.$set(this.masterCt.crionizacion, 2, "1");
        this.$set(this.masterCt.p_asisp, 2, "1");
        this.$set(this.masterCt.servnicho, 2, r.nicho);
        this.$set(this.masterCt.servpm, 2, r.cober);

        //observaciones introducido desde polizas
        var a='**Datos extraidos POLIZAS ASEGURADOS.' + (r.puni==1? '\nPRIMA UNICA.' : '' );
        this.$set(this.masterCt.callobs[4].comp, 'valueAux', (a + this.masterCt.callobs[4].comp.valueAux));
      },


      // entra al extraer una póliza de Mapfre
      // actualiza controles del schema con los datos de la póliza extraida
      extraidPolizaMapfre(r) {

        console.log('**extraid_polizaMF',r);

        if (!r) return;
               
        // Datos importados de la tabla de polizas poliza        
        this.$set(this.masterCt.callemp_tp, 2, "2");
        this.$set(this.masterCt.callemp, 2, 83);

        this.$set(this.masterCt.poliza, 2, r.poliza);
        this.$set(this.masterCt.ccomer, 2, r.ccomer);
        this.$set(this.masterCt.poliza_loc, 2, r.poliza_loc);
        this.$set(this.masterCt.poliza_cpo, 2, r.poliza_cpo);
        this.$set(this.masterCt.poliza_ase, 2, r.poliza_ase);  

        this.$set(this.masterCt.fhadhesion, 2, r.fhadhesion);
        this.$set(this.masterCt.ctramit, 2, r.ctramit);
        this.$set(this.masterCt.fallename, 2, r.fallename);        
        this.$set(this.masterCt.fallesexo, 2, r.fallesexo);
        this.$set(this.masterCt.rat, 2, String(r.rat));
        this.$set(this.masterCt.fallenacim, 2, r.fallenacim);
        this.$set(this.masterCt.falleedad, 2, r.falleedad);
        this.$set(this.masterCt.clavep, 2, r.clavep);
        this.$set(this.masterCt.agencia, 2, r.agencia);
        this.$set(this.masterCt.directo, 2, r.directo);
        this.$set(this.masterCt.zona_agencia, 2, r.zona_agencia);
        this.$set(this.masterCt.ramo, 2, r.ramo);
        this.$set(this.masterCt.modalidad, 2, r.modalidad);

        //importes
        this.$set(this.masterCt.servpm, 2, r.servpm);
        this.$set(this.masterCt.servnicho, 2, r.servnicho);
        this.$set(this.masterCt.servlapida, 2, r.servlapida);
        this.$set(this.masterCt.servtana, 2, r.servtana);
        this.$set(this.masterCt.servcomplemento, 2, r.servcomplemento);

        //checkbox -> convertir 0,1 a string
        this.$set(this.masterCt.local, 2, String(r.local));
        this.$set(this.masterCt.provincial, 2, String(r.provincial));
        this.$set(this.masterCt.nacional, 2, String(r.nacional));
        this.$set(this.masterCt.internacional, 2, String(r.internacional));
        this.$set(this.masterCt.apd, 2, String(r.apd));
        this.$set(this.masterCt.gestor, 2, String(r.gestor));
        this.$set(this.masterCt.asisp, 2, String(r.asisp));
        this.$set(this.masterCt.incineracion, 2, String(r.incineracion));
        this.$set(this.masterCt.inhumacion, 2, String(r.inhumacion));
        this.$set(this.masterCt.crionizacion, 2, String(r.crionizacion));

        if (r.ramo!= '030') {
            this.$set(this.masterCt.servasegdec, 2, "1");				 
        }
         
        /*Flink.F.llamada.verrecibos.visible=false;
          if (item.RECIBO_STD.substring(0,7)!="COBRADO"){
					Flink.F.llamada.verrecibos.visible=true
        } */			
        
        // generamos documento de Póliza
        this.consultaPoliza();
      },


      // genera documento de Póliza
      consultaPoliza() {
        var self= this;

        this.$root.$alert.open("¿Generamos Documento CONSULTA PÓLIZA?", "confirm")
        .then(async function(result) {
          if (!result) return;

          self.$emit('onEvent', { accion: 4 });

          // obtengo records
          let param = { apiArg: self.apiArgs.servicio, args: { id:self.ID }};
          let apiResult = await self.$store.dispatch("apiCall", param);
          
          if (apiResult.sql.error) self.$root.$alert.open(apiResult.sql.msg, 'error');
        });
      },


      // entra si detecta la clave de producción ha cambiado      
      changeClaveProduccion() {
        // si NO es Mapfre, salgo
        if (this.masterCt.callemp[2]!= 83) return;

        const self = this;
        this.$root.$alert
          .open("Si modifica la CLAVE DE PRODUCCIÓN<br>deberá introducir de nuevo los APDS", "confirm")
          .then(async function(r) { 
            if (r) return await self.modificarAgencia();            
            self.$set(self.masterCt.clavep, 2, self.record.clavep);
        });

      },


      // realiza una apiCall para obtener los datos de la agencia según
      // la clave de producción introducida
      async modificarAgencia() {
     
        let param = { apiArg: this.apiArgs.clave, args: { clave:this.masterCt.clavep[2] }};
        let apiResult = await this.$store.dispatch("apiCall", param);
      
        if (apiResult.sql.error) {
          this.$root.$alert.open(apiResult.sql.msg, 'error');
          return;
        } 
        
        // actualizo datos de controles con los recibidos       
        this.$set(this.masterCt.agencia, 2, apiResult.r[0].agencia);
        this.$set(this.masterCt.agencia[4].comp, 'valueAux', apiResult.r[0].name_agencia);
       
        // eliminio APDS
        this.$set(this.masterCt.apd1, 2, "0");
        this.$set(this.masterCt.apd1[4].comp, 'valueAux', "");

        this.$set(this.masterCt.apd2, 2, "0");
        this.$set(this.masterCt.apd2[4].comp, 'valueAux', "");
      },



      // FUNCIONES EXCLUSIVAS PARA EL MÓDULO DE APDS
      ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////    

    },
    

    computed:{

      noEdit() {
        return this.$store.getters[this.masterStore + "/noEdit"];
      },

      estado() {
        return this.$store.state[this.masterStore].estado;
      },

      record() {
        return this.$store.getters[this.masterStore + "/syncRecord"];
      },

      tipoViewAgencia() {
        return Number(this.masterCt.agencia[2])==1000 || Number(this.masterCt.agencia[2]== 0)? 'N' : 'FA'; 
      }
     
    }
  };
</script>
